import React from "react"
import "./team-member-content.scss"
import { TeamMemberContentProps } from "../../types/components"
import {
  FetchedThemePointProps,
  ArticleItemProps,
} from "../../types/shared-singulars"
import ThemePoint from "./theme-point"
import PostItem from "./post-item"
import Dropdown from "./dropdown"

const TeamMemberContent: React.FC<TeamMemberContentProps> = ({
  mainAreas,
  mainContent,
  themePoints,
  boxList,
  work,
}) => {
  return (
    <div className="team-member-content">
      <div className="team-member-content__wrapper container--xl">
        <div className="team-member-content__main">
          <Dropdown mainAreas={mainAreas} />
          <div className="team-member-content__main__left half--left">
            <h4>{mainAreas?.title}</h4>
            <ul>
              {mainAreas?.areas &&
                mainAreas?.areas.map(
                  (area: { title: string }, index: number) => (
                    <li key={index}>{area.title}</li>
                  )
                )}
            </ul>
          </div>
          <div className="team-member-content__main__right half--right">
            <h2
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-easing="ease-out"
            >
              {mainContent.title}
            </h2>
            <p
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-easing="ease-out"
            >
              {mainContent.content}
            </p>
          </div>
        </div>
        <div className="team-member-content__theme-points">
          {themePoints &&
            themePoints.map((item: FetchedThemePointProps, index: number) => (
              <div
                key={index}
                className="team-member-content__theme-points__item"
              >
                <ThemePoint
                  number={item.number}
                  content={item.content}
                  index={index + 1}
                />
              </div>
            ))}
        </div>
        <div className="team-member-content__box-list">
          <div className="team-member-content__box-list__left half--left">
            <span />
          </div>
          <div className="team-member-content__box-list__right half--right">
            <h4
              data-aos="fade-up"
              data-aos-delay="70"
              data-aos-easing="ease-out"
            >
              {boxList?.title}
            </h4>
            {boxList?.content &&
              boxList.content.map((box: { text: string }, index: number) => (
                <div
                  key={index}
                  className="team-member-content__box-list__right__item"
                  data-aos="fade-up"
                  data-aos-delay={(index + 1) * 50}
                  data-aos-easing="ease-out"
                >
                  <span>{box.text}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="team-member-content__case-studies">
          {work &&
            work.map((post: ArticleItemProps, index: number) => (
              <div
                key={index}
                className="team-member-content__case-studies__item"
              >
                <PostItem
                  uri={post?.uri}
                  imgProps={post?.featuredImage?.node}
                  title={post.title}
                  index={index + 1}
                  contentType={
                    post?.singleCasesOptions?.caseType
                      ? post?.singleCasesOptions?.caseType
                      : post?.singleArticleOptions?.articleType
                  }
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TeamMemberContent
