import React from "react"
import "./team-member-banner.scss"
import { CircleArrow, Email, Telephone } from "../elements/svg-elements"
import PageLink from "../shared/page-link"
import { TeamMemberBannerProps } from "../../types/components"

const TeamMemberBanner: React.FC<TeamMemberBannerProps> = ({
  title,
  position,
  phoneNumber,
  email,
  goBack,
  banner,
}) => {
  return (
    <div className="team-member-banner">
      <div className="team-member-banner__wrapper container--xl">
        <span
          className="team-member-banner__link"
          data-aos="fade-up
                    "
          data-aos-delay="50"
          data-aos-easing="ease-out"
        >
          <PageLink templateName="Team">
            <CircleArrow /> {goBack}
          </PageLink>
        </span>
        <div className="team-member-banner__markups">
          <h1
            data-aos="fade-up
                        "
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            {title}
          </h1>
          <h4
            data-aos="fade-up
                        "
            data-aos-delay="200"
            data-aos-easing="ease-out"
          >
            {position}
          </h4>
          <a
            href={`tel:${phoneNumber}`}
            className="team-member-banner__markups__tel"
            data-aos="fade-up
                        "
            data-aos-delay="300"
            data-aos-easing="ease-out"
          >
            <Telephone />
            {phoneNumber}
          </a>
          <a
            href={`mailto:${email}`}
            className="team-member-banner__markups__email"
            data-aos="fade-up
                        "
            data-aos-delay="350"
            data-aos-easing="ease-out"
          >
            <Email />
            {email}
          </a>
        </div>
        <div className="team-member-banner__image">
          {banner && (
            <img
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-easing="ease-out"
              alt={banner.altText}
              srcSet={banner.sourceUrl}
              sizes={banner.sizes}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamMemberBanner
