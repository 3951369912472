import React from 'react';
import './theme-point.scss';
import { ThemePointProps } from '../../types/components';

const ThemePoint: React.FC<ThemePointProps> = ({number, content, index}) => {
    return (
        <div 
            className="theme-point"
            data-aos="fade-up" 
            data-aos-delay={index * 100}
            data-aos-easing="ease-out"
        >
            <span className="theme-point__number">{number}</span>
            <h5>{content}</h5>
        </div>
    );
};

export default ThemePoint;