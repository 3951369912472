import React from "react"
import { graphql, PageProps } from "gatsby"
import { TeamMemberPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import TeamMemberBanner from "../components/team-member/team-member-banner"
import TeamMemberContent from "../components/team-member/team-member-content"

const TeamMember: React.FC<PageProps<TeamMemberPageProps>> = ({ data }) => {
  const { singleTeam, featuredImage } = data?.PAGE_CONTENT

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <TeamMemberBanner
        title={data?.PAGE_METADATA?.title}
        position={singleTeam?.position}
        phoneNumber={singleTeam?.phoneNumber}
        email={singleTeam?.email}
        goBack={singleTeam?.goBack}
        banner={featuredImage?.node}
      />
      <TeamMemberContent
        mainAreas={singleTeam?.mainAreasOfWork}
        mainContent={singleTeam?.mainContent}
        themePoints={singleTeam?.themePoints}
        boxList={singleTeam?.boxList}
        work={singleTeam?.work}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpTeam(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpTeam(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      singleTeam {
        position
        phoneNumber
        email
        goBack
        mainAreasOfWork {
          title
          areas {
            title
          }
        }
        mainContent {
          title
          content
        }
        themePoints {
          content
          number
        }
        boxList {
          title
          content {
            text
          }
        }
        work {
          ... on WpCase {
            id
            uri
            title
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            singleCasesOptions {
              caseType
            }
          }
          ... on WpArticle {
            id
            uri
            title
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            singleArticleOptions {
              articleType
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          sizes
          sourceUrl
          srcSet
        }
      }
    }
  }
`

export default TeamMember
