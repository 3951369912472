import React, { useState } from "react"
import { DropDownProps } from "../../types/components"
import { PlusIcon } from "../elements/svg-elements"
import "./dropdown.scss"

const Dropdown: React.FC<DropDownProps> = ({ mainAreas }) => {
  const [accordion, setAccordion] = useState<boolean>(false)
  return (
    <div className="dropdown container--xl">
      <div
        className={accordion ? "dropdown__button shown" : "dropdown__button"}
        onClick={() => setAccordion(!accordion)}
      >
        <h4>{mainAreas.title}</h4>
        <PlusIcon />
      </div>
      {accordion && (
        <div className="dropdown__content">
          <ul>
            {mainAreas.areas &&
              mainAreas.areas.map((area: { title: string }, index: number) => (
                <li key={index}>{area.title}</li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown
