import { Link } from 'gatsby';
import './post-item.scss';
import React from 'react';
import Img from '../shared/img';
import { TeamPostItemProps } from '../../types/components';

const PostItem: React.FC<TeamPostItemProps> = ({
    imgProps, 
    title, 
    index, 
    uri, 
    contentType
}) => {
    return (
        <Link 
            to={uri}
            className="post-item"
            data-aos="fade-up" 
            data-aos-delay={index * 50}
            data-aos-easing="ease-out"
        >
            <span className="post-item__tag">
                {contentType}
            </span>
            <div className="post-item__image-wrapper">
                <Img imgProps={imgProps} />
            </div>
            <span className="post-item__title">
                {title}
            </span>
        </Link>
    );
};

export default PostItem;